
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  body{
    @apply bg-bg-color  text-gray-800  font-google 
    dark:bg-darkMode dark:text-white
  }

  h1,h2,h3,h4,h5,h6{
    @apply font-bold
  }

  h1{
    @apply text-4xl sm:text-5xl md:text-6xl

  }

  h1{
    @apply text-3xl sm:text-4xl
  }

  li {
    @apply cursor-pointer
  }

  .button {
    @apply shadow-xl shadow-gray-400 rounded-md bg-primary
    text-white uppercase hover:bg-indigo-700
    
    dark:bg-darkGradient1 dark:shadow-gray-900
    dark:hover:bg-darkGradient2 dark:shadow-gray-900

    

  }

  .social-icon{
    @apply rounded-full shadow-lg shadow-gray-400 cursor-pointer 
      hover:scale-105 ease-in duration-500 p-3

      dark:shadow-stone-900 dark:bg-neutral-700
  }

  .my-container{
    @apply max-w-[1240px] mx-auto  
  }

  .title {
    @apply py-2 uppercase tracking-widest text-primary
  }

  .gradientText{
    @apply  text-transparent bg-clip-text bg-gradient-to-r from-darkGradient1 to-darkGradient2
  }

  .gradientBg{
    @apply bg-gradient-to-r from-darkGradient1 to-darkGradient2
  }

  .gradientBgReverse{
    @apply bg-gradient-to-r from-darkGradient2 to-darkGradient1
  }
  .active {
      @apply gradientText
  }

  .hero{
    @apply bg-gradient-to-r from-[#18181b] to-[#131c31]
  }
}